/******* screen-large.less *******/
/******* mixins.less 2013-1-16 *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/******* hide-mobile.less 2013-1-16 *******/
#mobile-navi,
.togglenavigation,
.cb-mobile-access,
.cb-mobile-navigation,
.body-mobile {
  display: none;
}
.cb-mobile-navigation {
  opacity: 0;
  -o-transition: none;
  -moz-transition: none;
  -webkit-transition: none;
  transition: none;
}
.cb-mobile-invisible,
.body-non-mobile {
  display: block;
}
/******* navigation-large.less 2013-1-16 *******/
.navi,
.navi > .item,
.dock {
  display: block;
  float: left;
}
.menu {
  display: block;
}
/* LEVEL 1 */
div.sub1 {
  float: right;
  margin: 20px 16px 0 0;
  position: relative;
  z-index: 2;
}
.sub1 > .item {
  position: relative;
  margin: 0 8px;
}
.sub1 > .item.init {
  margin-left: 0;
}
.sub1 > .item.exit {
  margin-right: 0;
}
.sub1 > .item:hover > .menu,
.sub1 > .item:focus > .menu {
  color: #f39b00;
}
div.sub1 .menu {
  text-transform: uppercase;
  color: #000;
  line-height: 24px;
  padding-bottom: 28px;
  white-space: nowrap;
}
div.sub1 a.path {
  color: #f39b00;
}
/* LEVEL 2 */
div.sub2 {
  background: #000;
  position: absolute;
  top: 100%;
  padding: 40px;
  display: none;
}
div.sub1 .item.exit div.sub2 {
  right: 0;
}
.cbe--edit-navigation div.sub1 .item.path div.sub2,
div.sub1 .item:hover div.sub2 {
  display: block;
}
.sub2 > .item {
  width: 100%;
  margin-bottom: 3px;
}
.sub2 > .item.exit {
  margin-bottom: 0;
}
div.sub2 .menu {
  color: #fff;
  padding: 3px 10px 0;
  background: #333;
  line-height: 22px;
}
div.sub2 .menu:hover,
div.sub2 .menu:focus {
  color: #f39b00;
}
/******* layout-large.less 2013-1-16 *******/
.desk {
  max-width: 100%;
}
.footcontent {
  font-size: 14px;
}
.footpart {
  min-width: 140px;
}
#expo div.link tr.cb-index-all {
  width: 100%;
  left: 0%;
}
@media only screen and (min-width: 800px) {
  #expo div.link tr.cb-index-all {
    width: 800px;
    left: 50%;
    margin-left: -400px;
  }
}
div.base div.unit.fold div.ctrl {
  left: 0;
  width: 100%;
}
@media only screen and (min-width: 800px) {
  div.base div.unit.fold div.ctrl {
    width: 800px;
    left: 50%;
    margin-left: -400px;
  }
}
.im-mood__part:hover .im-mood__pic {
  mix-blend-mode: multiply;
}
.im-mood__part:hover .im-mood__claim {
  background-size: 46px auto;
  background-position: 50% 7px;
}
.area {
  width: 824px;
}
.area > .unit {
  margin-right: 0px;
  margin-left: 0px;
  width: 824px;
}
.area h2,
.area .foot {
  margin-right: 12px;
  margin-left: 12px;
}
.area .part,
.area > .grid table {
  margin-right: 12px;
  margin-left: 12px;
  width: 800px;
}
.area .tiny {
  width: 250px;
}
.area > .slim {
  width: 274.66666667px;
}
.area > .slim .part,
.area > .slim.grid table {
  width: 250.66666667px;
}
.area > .slim h2,
.area > .slim .foot,
.area > .slim .part,
.area > .slim.grid table {
  margin-right: 12px;
  margin-left: 12px;
}
.cb-layout2 .main {
  width: 824px;
}
.cb-layout2 .main > .unit {
  margin-right: 0px;
  margin-left: 0px;
  width: 824px;
}
.cb-layout2 .main h2,
.cb-layout2 .main .foot {
  margin-right: 12px;
  margin-left: 12px;
}
.cb-layout2 .main .part,
.cb-layout2 .main > .grid table {
  margin-right: 12px;
  margin-left: 12px;
  width: 800px;
}
.cb-layout2 .main .tiny {
  width: 388px;
}
.cb-layout2 .main > .slim {
  width: 412px;
}
.cb-layout2 .main > .slim .part,
.cb-layout2 .main > .slim.grid table {
  width: 388px;
}
.cb-layout2 .main > .slim .tiny {
  margin-right: 12px;
  margin-left: 12px;
  width: 182px;
}
.cb-layout2 .main > .slim h2,
.cb-layout2 .main > .slim .foot,
.cb-layout2 .main > .slim .part,
.cb-layout2 .main > .slim.grid table {
  margin-right: 12px;
  margin-left: 12px;
}
.base {
  width: 100%;
}
.base > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.base h2,
.base .foot {
  margin-right: 0%;
  margin-left: 0%;
}
.base .part,
.base > .grid table {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.base > .slim .part,
.base > .slim.grid table {
  width: 100%;
}
.farwest {
  width: 824px;
}
.farwest > .unit {
  margin-right: 0px;
  margin-left: 0px;
  width: 824px;
}
.farwest h2,
.farwest .foot {
  margin-right: 12px;
  margin-left: 12px;
}
.farwest .part,
.farwest > .grid table {
  margin-right: 12px;
  margin-left: 12px;
  width: 800px;
}
.farwest .tiny {
  width: 388px;
}
.farwest > .slim {
  width: 412px;
}
.farwest > .slim .part,
.farwest > .slim.grid table {
  width: 388px;
}
.farwest > .slim .tiny {
  margin-right: 12px;
  margin-left: 12px;
  width: 182px;
}
.farwest > .slim h2,
.farwest > .slim .foot,
.farwest > .slim .part,
.farwest > .slim.grid table {
  margin-right: 12px;
  margin-left: 12px;
}
.unit.form .tile .name,
.unit a.capt {
  float: left;
  width: 33%;
}
.unit.form .tile .chop,
.unit.form .tile textarea,
.unit.form .tile input.text,
.unit.form .tile .ctrl,
.unit input.capt {
  float: right;
  width: 63%;
}
/*.unit {
  margin-top: 10px;
  margin-bottom: 10px;
}*/
.main {
  margin-left: -12px;
}
.farwest {
  margin-left: -12px;
}
.south {
  margin-left: -12px;
}
.cb-layout1 .main .seam,
.cb-layout1 .south .seam,
.cb-layout3 .main .seam,
.areaTen .seam {
  margin-left: 12px;
  margin-right: 12px;
  width: 250px;
}
div.base div.unit div.head,
div.base div.unit div.body,
div.base div.unit div.foot {
  max-width: 100%;
  width: 800px;
}
div.base div.unit.fold div.body {
  max-width: 100%;
}
div.base div.unit.fold div.body div.more {
  max-width: 100%;
  width: 800px;
}
div.areaEleven .seam .part.tall {
  float: right;
  margin-left: 40px !important;
  width: 66% !important;
}
div.areaEleven .seam .part.tiny {
  width: calc(34% - 40px) !important;
}
#disp {
  left: 50%;
  margin-left: -175px;
  width: 350px;
  font-size: 15px;
  font-size: 1.5rem;
  top: 120px !important;
}
#disp.zoom {
  left: 50%;
  margin-left: -385px;
  width: 770px;
}
#disp.tube {
  left: 50%;
  margin-left: -330px;
  width: 660px;
}
#disp.site > .body {
  overflow: auto;
  max-height: 400px;
}
/*#disp.site li,
div.cb-sitemap li {
  .font-size(14); 
}*/
/*# sourceMappingURL=./screen-large.css.map */